import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActorType, User } from '../model/user';
import { SchemaSatisfaction } from '../message/message.component';
import { environment } from '../../environments/environment';
import { Session, TriggerStatus, TriggerData, EngageType, ChatEventType } from '../model/Session';
import { Message } from '../model/message';

@Injectable({
  providedIn: 'root',
})
export class BackendService {
  constructor(private httpClient: HttpClient) {
    const url = new URL(document.URL);
    this.host = url.searchParams.get('host');
    this.sessionId = url.searchParams.get('uid');
    this.accountId = url.searchParams.get('aid');
  }

  public async getUids(accountToken: string) {
    this.host = environment.backendUrl;
    const result = await this.httpClient
      .get<{
        id: string;
        accountId: string;
        clearTpCookies: boolean;
        reset: boolean;
        fullHeight: boolean;
        widgetHost: string;
        ip: string | null;
      }>(`${this.host}/api/widget/v1.0/uid?token=${accountToken}&consentReceived=true`)
      .toPromise();
    this.sessionId = result.id;
    this.accountId = result.accountId;
    return {
      sessionId: result.id,
      accountId: result.accountId,
      resetSession: result.reset,
      storeIp: result.ip,
    };
  }

  public host: string;
  public sessionId: string;
  public accountId: string;
  public async handshake(
    host: string,
    sessionId: string,
    accountId: string,
    pathname: string,
    pageUrl: string,
    pageTitle: string,
    language: string,
    visitorEmail: string,
    visitorFirstName: string,
    visitorLastName: string,
    rawCustomData: string,
    startWithWidgetOpen: boolean,
    resetSession: boolean,
    userAgent: string,
    isMobile: boolean,
    ghostMode: boolean,
    storeIp: string | null,
    urlDefinedSid: boolean,
    prefixedSession: string | null,
  ) {
    const result = await this.httpClient
      .post<{
        clearThirdPartyCookies: boolean;
        firebaseSessionId: string;
        environment: string;
        now: number;
        initSession: Session;
        initMessages: Message[];
        accountName: string;
        referralRule: 'normal' | 'hide' | 'noLink';
      }>(`${host}/api/chat/handshake`, {
        sessionId,
        accountId,
        pathname,
        pageUrl,
        pageTitle,
        language,
        visitorEmail,
        visitorFirstName,
        visitorLastName,
        rawCustomData,
        startWithWidgetOpen,
        resetSession,
        userAgent,
        isMobile,
        ghostMode,
        storeIp,
        urlDefinedSid,
        prefixedSession,
      })
      .toPromise();
    return result;
  }

  public async leaveGhostMode(sessionId: string, tempSession: Session, tempMessages: Message[]) {
    const result = await this.httpClient
      .post<{ sessionId: string }>(`${this.host}/api/chat/leave-ghost-mode`, {
        sessionId,
        tempSession,
        tempMessages,
      })
      .toPromise();
    if (result.sessionId !== this.sessionId) {
      this.sessionId = result.sessionId;
    }
    return result;
  }

  public async resetGhostSession(session: Session) {
    const result = await this.httpClient
      .post<Session>(`${this.host}/api/chat/reset-ghost-session`, session)
      .toPromise();
    return result;
  }

  public async getNewLead(sessionId: string, accountId: string) {
    const result = await this.httpClient
      .get<User>(`${this.host}/api/chat/new-lead`, {
        params: {
          accountId,
          sessionId,
        },
      })
      .toPromise();
    return result;
  }

  public async sendIsMobile(isMobile: boolean) {
    const result = await this.httpClient
      .post<{ isMobile: boolean }>(`${this.host}/api/chat/${this.sessionId}/is-mobile`, {
        isMobile,
      })
      .toPromise();
    return result;
  }

  public async sendZendeskIsTyping(isTyping: boolean, agent: User) {
    const result = await this.httpClient
      .put<{ agentIsTyping: boolean }>(`${this.host}/api/chat/${this.sessionId}/zendesk-is-typing`, { isTyping, agent })
      .toPromise();
    return result;
  }

  public async sendMessage(message: Message, pageUrl: string, pageTitle: string, forceUpdatedAt = false) {
    const result = await this.httpClient
      .post<Message>(
        `${this.host}/api/chat/${this.sessionId}/messages`,
        { message, data: { pageUrl, pageTitle } },
        {
          params: {
            forceUpdatedAt: `${forceUpdatedAt}`,
          },
        },
      )
      .toPromise();
    return result;
  }

  public async sendEngageMessage(triggerData: TriggerData) {
    const result = await this.httpClient
      .post<Message[]>(`${this.host}/api/chat/${this.sessionId}/engage-messages`, triggerData)
      .toPromise();
    return result;
  }

  public async notifyEngage(
    type: EngageType,
    pageUrl: string,
    pageTitle: string,
    triggerId?: string,
    welcomeMessageId?: string,
  ) {
    const result = await this.httpClient
      .post(`${this.host}/api/chat/${this.sessionId}/engage`, {
        type,
        pageUrl,
        pageTitle,
        triggerId,
        welcomeMessageId,
      })
      .toPromise();
    return result;
  }

  public async updateTriggerStatus(status: TriggerStatus) {
    const result = await this.httpClient
      .put<{ status: TriggerStatus }>(`${this.host}/api/chat/${this.sessionId}/trigger-status`, {
        status,
      })
      .toPromise();
    return result;
  }

  public async requestCloudinarySignature(timestamp: string, uploadPreset: string, folderName: string) {
    const result = await this.httpClient
      .get<{ signature: string }>(`${this.host}/api/chat/cloudinary-signature`, {
        params: {
          timestamp,
          uploadPreset,
          folderName,
        },
      })
      .toPromise();

    return result.signature;
  }

  public async updateThirdPartyData(data: any) {
    const result = await this.httpClient.put<any>(`${this.host}/api/chat/${this.sessionId}/tp-data`, data).toPromise();
    return result;
  }

  public async updateThirdPartyAgentPresence(hasAgents: boolean) {
    const result = await this.httpClient
      .put(`${this.host}/api/chat/${this.sessionId}/tp-agent-present`, { hasAgents })
      .toPromise();
  }

  public async setSatisfaction(satisfaction: number, comment: string) {
    const result = await this.httpClient
      .put(`${this.host}/api/chat/${this.sessionId}/satisfaction`, { satisfaction, comment })
      .toPromise();
  }

  public async skipSatisfaction() {
    const result = await this.httpClient.delete(`${this.host}/api/chat/${this.sessionId}/satisfaction`).toPromise();
  }

  public async setSchemaSatisfaction(
    satisfaction: SchemaSatisfaction,
    webchatMessageId: string,
  ): Promise<{ success: boolean }> {
    const result = await this.httpClient
      .post<{ success: boolean }>(`${this.host}/api/chat/${this.sessionId}/schemaSatisfaction`, {
        webchatMessageId,
        satisfaction,
      })
      .toPromise();

    return result;
  }

  public async sendMessageToFront(message: Message, initJson: any) {
    const result = await this.httpClient
      .post(`${this.host}/api/chat/${this.sessionId}/front/messages`, { message, initJson })
      .toPromise();
    return result;
  }

  public async sendHistoryToFront(messages: Message[], initJson: any) {
    const result = await this.httpClient
      .post<Message>(`${this.host}/api/chat/${this.sessionId}/front/history`, {
        messages,
        initJson,
      })
      .toPromise();
    return result;
  }

  public async sendMessageToEasichat(message: Message, initJson: any) {
    const result = await this.httpClient
      .post(`${this.host}/api/chat/${this.sessionId}/easichat/messages`, { message, initJson })
      .toPromise();
    return result;
  }

  public async sendHistoryToEasichat(messages: Message[], initJson: any) {
    const result = await this.httpClient
      .post<Message>(`${this.host}/api/chat/${this.sessionId}/easichat/history`, {
        messages,
        initJson,
      })
      .toPromise();
    return result;
  }

  public async selfCareAskQuestion() {
    const result = (await this.httpClient
      .post(`${this.host}/api/chat/${this.sessionId}/self-care-ask-question`, {})
      .toPromise()) as { success: boolean };
    return result;
  }

  public async selfCareReset(pageUrl: string, pageTitle: string, isGhostMode: boolean, currentSession: Session) {
    const result = (await this.httpClient
      .post(`${this.host}/api/chat/${this.sessionId}/self-care-reset`, {
        pageUrl,
        pageTitle,
        isGhostMode,
        currentSession,
      })
      .toPromise()) as { success: boolean; session?: Session };
    return result;
  }

  public async setUserIdentity(
    identity: {
      email: string;
      firstName: string;
      lastName: string;
      customData: { key: string; value: string }[];
    },
    parentPageUrl: string,
  ) {
    const result = (await this.httpClient
      .put(`${this.host}/api/chat/${this.sessionId}/set-user-identity`, {
        ...identity,
        href: parentPageUrl,
      })
      .toPromise()) as {
      email: string;
      firstName: string;
      lastName: string;
      type: ActorType;
      id: string;
      customData: { key: string; value: string }[];
      avatar: string;
    };
    return result;
  }

  public async resetUser() {
    const result = (await this.httpClient
      .post(`${this.host}/api/chat/${this.sessionId}/reset-user`, {})
      .toPromise()) as {
      email: string;
      firstName: string;
      lastName: string;
      type: ActorType;
      id: string;
      customData: { key: string; value: string }[];
      avatar: string;
    };
    return result;
  }

  public async hrefChanged(href: string, pageName: string, ghostMode: boolean, accountId: string, session: Session) {
    const result = await this.httpClient
      .post<Session>(`${this.host}/api/chat/${this.sessionId}/href-changed`, {
        href,
        pageName,
        ghostMode,
        accountId,
        session,
      })
      .toPromise();
    return result;
  }

  public async logEvent(type: ChatEventType, uid?: string, data?: any) {
    const result = await this.httpClient
      .post(
        `${this.host}/api/chat/${this.sessionId}/evt`,
        { type, data, accountId: this.accountId },
        { params: uid ? { uid } : {} },
      )
      .toPromise();
  }
}
