import { Injectable } from '@angular/core';
import { ModalComponent } from './modal.component';

@Injectable({ providedIn: 'root' })
export class ModalService {
  private modals: ModalComponent[] = [];

  /** @description Add modal to array of active modals */
  public add(modal: any) {
    this.modals.push(modal);
  }

  /** @description Remove modal from array of active modals */
  public remove(id: string) {
    this.modals = this.modals.filter((x) => x.id !== id);
  }

  /** @description Open modal specified by id */
  public open(id: string) {
    const modal = this.modals.find((x) => x.id === id);
    modal.open();
  }

  /** @description Close modal specified by id */
  public close(id: string) {
    const modal = this.modals.find((x) => x.id === id);
    modal.close();
  }

  /** @description Check if modal exists and is open */
  public isOpen(id: string) {
    const modal = this.modals.find((x) => x.id === id);
    return modal ? modal.isOpen : false;
  }
}
