import { Component, EventEmitter, Output, SecurityContext } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';

import { DisplayService } from 'src/app/services/display.service';

export type FeedbackPayload = { comment: string };

@Component({
  selector: 'app-satisfaction-feedback',
  templateUrl: './satisfaction-feedback.component.html',
  styleUrls: ['./satisfaction-feedback.component.scss'],
})
export class SatisfactionFeedbackComponent {
  @Output() validateFeedback = new EventEmitter<FeedbackPayload>();

  satisfactionComment = new UntypedFormControl('');

  constructor(
    public displayService: DisplayService,
    private domSanitizer: DomSanitizer,
  ) {}

  handleValidateFeedback(): void {
    const comment = this.domSanitizer.sanitize(SecurityContext.HTML, this.satisfactionComment.value);
    this.validateFeedback.emit({
      comment,
    });
  }
}
