import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { v4 as uuidv4 } from 'uuid';

@Injectable({ providedIn: 'root' })
export class AddHeaderInterceptor implements HttpInterceptor {
  private wid: string;

  constructor() {
    // Build an id for the widget instance lifetime.
    const uniqueId = uuidv4() ?? '';
    const [shortUid] = uniqueId.split('-');
    this.wid = shortUid ?? '';
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Clone the request to add the new header
    const clonedRequest = req.clone({ headers: req.headers.append('Bc-Wid', this.wid) });

    // Pass the cloned request instead of the original request to the next handle
    return next.handle(clonedRequest);
  }
}
