<p *ngIf="message?.length" class="preamble" [innerHTML]="message | sanitizeMessage"></p>

<div fxFlex="grow" fxLayout="row" fxLayoutAlign="center center">
  <rmx-icon fxFlex="nogrow" fxFlexOrder="1" fxLayoutAlign="start center" name="arrow-left-s-line"
    *ngIf="carouselMediaContainer?.nativeElement"
    [ngStyle]="{color: displayService.chatStyle.headerStyle.background}"
    class="clickable"
    (click)="moveToCarouselItem('left')"
  ></rmx-icon>

  <rmx-icon
    fxFlex="nogrow"
    fxFlexOrder="3"
    fxLayoutAlign="end center"
    name="arrow-right-s-line"
    style="margin-left: auto"
    *ngIf="carouselMediaContainer?.nativeElement"
    [ngStyle]="{color: displayService.chatStyle.headerStyle.background}"
    class="clickable"
    (click)="moveToCarouselItem('right')"
  ></rmx-icon>

  <div fxFlex="grow" fxFlexOrder="2" fxLayout="row" class="media-container" [ngStyle]="{
      'border-bottom-left-radius.px': currentCarouselItem.description && currentCarouselItem.description !== '' ? '0' : '5',
      'border-bottom-right-radius.px': currentCarouselItem.description && currentCarouselItem.description !== '' ? '0' : null
    }" #mediaContainer>
    <ng-container *ngFor="let carouselItem of carouselItems; let carouselItemIndex = index">
      <ng-container [ngSwitch]="carouselItem.mediaType">
        <img fxFlex="grow"
        *ngSwitchCase="'image'"
        [src]="carouselItem.mediaUrl"
        [ngClass]="{media: true, image: true, clickable: carouselItem.clickable?.length > 0 && carouselItem.clickable.includes('mediaUrl')}"
        [ngStyle]="{ 'z-index': carouselItems.length - carouselItemIndex }"
        (click)="carouselItem.clickable.includes('mediaUrl') ? viewOpenUrl(carouselItem.url) : null" />
        <video controls autoplay muted loop fxFlex="grow" *ngSwitchCase="'video'" class="media video" [ngClass]="{
            clickable:
              carouselItem.clickable?.length > 0 &&
              carouselItem.clickable.includes('mediaUrl')
          }" [ngStyle]="{ 'z-index': carouselItems.length - carouselItemIndex }" [src]="carouselItem.mediaUrl" (click)="
            carouselItem.clickable.includes('mediaUrl') ? viewOpenUrl(carouselItem.url) : null
          "></video>
      </ng-container>
    </ng-container>
  </div>
</div>

<div
  fxFlex="nogrow"
  *ngIf="currentCarouselItem.description"
  class="description"
  [ngClass]="{
    'no-top-border-radius':
      currentCarouselItem.mediaUrl && currentCarouselItem.mediaUrl !== '',
    clickable:
      currentCarouselItem.clickable?.length > 0 &&
      currentCarouselItem.clickable.includes('description')
  }"
  [ngStyle]="displayService.getMessageStyle(isVisitor, isDelivered)"
  (click)="
    currentCarouselItem.clickable.includes('description')
      ? viewOpenUrl(currentCarouselItem.url)
      : null
  "
  (mouseover)="hoverInd = carouselIndex"
  (mouseleave)="hoverInd = -1"
  [innerHTML]="currentCarouselItem.description | sanitizeMessage">
</div>
