<div fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="20px">
  <p fxFlex="nogrow">{{ 'SATISFACTION_PROMPT_COMMENT' | translate }}</p>
  <textarea
    fxFlex="nogrow"
    spellcheck="false"
    [placeholder]="'COMMENT_HERE' | translate"
    [formControl]="satisfactionComment"
  >
  </textarea>
  <button
    fxFlex="nogrow"
    [ngStyle]="{
      backgroundColor: displayService.chatStyle.sendButtonColor,
      borderColor: displayService.chatStyle.sendButtonColor,
      color: 'white'
    }"
    (click)="handleValidateFeedback()"
  >
    {{ 'VALIDATE_SATISFACTION_PROMPT' | translate }}
  </button>
</div>
