<button
  class="main"
  #chatButton
  @scaleUpWidgetButton
  (click)="toggleChatStatus(true)"
  [ngStyle]="displayService.getlauncherButtonStyle()"
  [ngClass]="{
    mobile: displayService.isMobileDisplay,
    widgetOnRight: displayService.widgetOnRight
  }"
>
 <span class="sr-only" *ngIf="displayService.spanButtonLabel">{{displayService.spanButtonLabel}}</span>
  <ng-container
    *ngIf="displayService.chatStyle.launcherAnimationUrl"
    [ngTemplateOutlet]="launcherAnimation"
  ></ng-container>
  <ng-template #launcherAnimation>
    <video
      muted
      preload="auto"
      class="launcherAnimation"
      [volume]="0"
      [defaultMuted]="true"
      [ngStyle]="{
        opacity: animationLoaded && showAnimation ? 1 : 0,
        'width.px': displayService.isMobileDisplay ? 40 : 60,
        'height.px': displayService.isMobileDisplay ? 40 : 60,
        'border-radius.px': displayService.isMobileDisplay ? 40 : 60
      }"
      (ended)="stopAnimation()"
      (canplay)="animationLoaded = true"
      #animationVideo
    >
      <source [src]="displayService.chatStyle.launcherAnimationUrl" type="video/mp4" />
    </video>
  </ng-template>

  <rmx-icon name="chat-3-line" class="s-2x"></rmx-icon>
  <div *ngIf="displayService.newMessages" class="newMessages">{{ displayService.newMessages }}</div>
</button>
