<div fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="20px">
  <span fxFlex="nogrow">{{ 'SATISFACTION_PROMPT' | translate }}</span>
  <div fxFlex="nogrow" fxFill fxLayout="row" fxLayoutAlign="space-between start">
    <div
      fxFlex="nogrow"
      fxLayout="column"
      fxLayoutAlign="center center"
      fxLayoutGap="8px"
      class="satisfaction-vote-button-container"
      *ngFor="let note of satisfactionNotes; let isFirst = first; let isLast = last"
    >
      <button
        fxFlex="nogrow"
        type="button"
        class="satisfaction-vote-button"
        [ngClass]="{ 'worst-note': isFirst, 'best-note': isLast }"
        (click)="calculateNote(note)"
      >
        {{ note }}
      </button>
      <span fxFlex="nogrow" *ngIf="isFirst">{{ 'SATISFACTION_SCORE_WORST' | translate }}</span>
      <span fxFlex="nogrow" *ngIf="isLast">{{ 'SATISFACTION_SCORE_BEST' | translate }}</span>
    </div>
  </div>
  <span fxFlex="nogrow" class="satisfaction-action-button" (click)="skipSatisfaction.emit()">
    {{ 'SKIP_SATISFACTION_PROMPT' | translate }}
  </span>
</div>
