import { SchemaSatisfaction } from '../message/message.component';
import { CarouselItem } from './carousel.model';
import { User } from './user';

export enum MessageType {
  TEXT = 'text',
  TEXT_PAYLOAD = 'text_payload',
  IMAGE = 'image',
  QUICK_REPLIES = 'quick_replies',
  BUTTONS = 'buttons',
  ATTACHMENT = 'attachment',
  VIDEO = 'VIDEO',
  WEBVIEW = 'webview',
  CAROUSEL = 'carousel',
  ORDER_TRACKING = 'order_tracking',
  CUSTOMER_SCRIPT = 'customer_script',
  STREAMED_TEXT = 'streamed_text',
}

export type TextMessageContent = string;

export interface TextPayloadMessageContent {
  text: string;
  payload: any;
}

export type ImageMessageContent = string;

export type VideoMessageContent = string;

export type QuickRepliesMessageContent = string[];

export interface ButtonContent {
  type: 'reply' | 'link';
  text: string;
  content: string; // Payload for replies, urls for links
}

export type ButtonsMessageContent = ButtonContent[];

export type CarouselMessageContent = CarouselItem[];
export type HtmlCarouselMessageContent = { message: string; items: CarouselItem[] };

export interface AttachmentMessageContent {
  url: string;
  contentType: null | 'pdf' | 'video';
}

export type WebviewMessageContent = string;

export type OrderTrackingMessageContent = {
  orderNumber: string;
  orderStatus: OrderTrackingStatus | string; // `string` if the value isn't parsable as first type
  orderStatusLink: URL | string; // `string` if the value isn't parsable as first type
  trackingButtonLabel?: string;
  customStatusImageUrl?: URL | string; // `string` if the value isn't parsable as first type
  customStatusTitle?: string;
  customStatusLabel?: string;
};

export interface OrderTrackingRegisteredData extends OrderTrackingMessageContent {
  orderStatus: 'REGISTERED';
  estimatedDeliveryDate: string; // `string` if the value isn't parsable as first type
}

export interface OrderTrackingShippedData extends OrderTrackingMessageContent {
  orderStatus: 'SHIPPED';
  estimatedDeliveryDate: string; // `string` if the value isn't parsable as first type
}

export interface OrderTrackingAvailableToWithdrawData extends OrderTrackingMessageContent {
  orderStatus: 'AVAILABLE_TO_WITHDRAW';
  deliveryDate: string; // `string` if the value isn't parsable as first type
}

export interface OrderTrackingCollectedData extends OrderTrackingMessageContent {
  orderStatus: 'COLLECTED';
  deliveryDate: string; // `string` if the value isn't parsable as first type
}

export const orderTrackingStatus = ['REGISTERED', 'SHIPPED', 'AVAILABLE_TO_WITHDRAW', 'COLLECTED'] as const;
export type OrderTrackingStatus = (typeof orderTrackingStatus)[number];

export type StreamedTextMessageContent = {
  text: string;
  done: boolean;
};

export type MessageContent =
  | TextMessageContent
  | TextPayloadMessageContent
  | ImageMessageContent
  | QuickRepliesMessageContent
  | ButtonsMessageContent
  | CarouselMessageContent
  | AttachmentMessageContent
  | VideoMessageContent
  | WebviewMessageContent
  | CarouselMessageContent
  | OrderTrackingMessageContent
  | StreamedTextMessageContent;

export interface Message {
  author: User | null;
  sentDate: number;
  updatedDate: number;
  content: MessageContent;
  type: MessageType;
  uuid: string;
  delivered: boolean;
  noNotif: boolean;
  isWelcome: boolean;
  isEngage: boolean;
  popNotif: boolean;
  isButtonReply: boolean;
  isWelcomeEngageButtonReply: boolean;
  isLastStep: boolean;
  schemaSatisfaction: SchemaSatisfaction;
  showSchemaSatisfaction: boolean;
  webchatMessageId: string | null;
  hideTextInput?: boolean;
  mediaLoaded?: boolean;
}

export enum ThreadStatus {
  NEW = 'new',
  ENGAGED = 'engaged',
  LIVE_ENGAGED = 'live_engaged',
  WELCOMED = 'welcomed',
  ASSIGNED = 'assigned',
  CLOSED = 'closed',
  REDIRECTED = 'redirected',
}
