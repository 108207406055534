import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';

import { TranslateModule } from '@ngx-translate/core';

import { ReactiveFormsModule } from '@angular/forms';
import { SatisfactionRatingComponent } from './satisfaction-rating/satisfaction-rating.component';
import { SatisfactionFeedbackComponent } from './satisfaction-feedback/satisfaction-feedback.component';

@NgModule({
  declarations: [SatisfactionRatingComponent, SatisfactionFeedbackComponent],
  imports: [CommonModule, TranslateModule, FlexLayoutModule, ReactiveFormsModule],
  exports: [SatisfactionRatingComponent, SatisfactionFeedbackComponent],
})
export class SatisfactionModule {}
