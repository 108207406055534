import { DatePipe } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { Injector, NgModule } from '@angular/core';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { ClickOutsideModule } from '@solidexpert/ng-click-outside';
import {
  RemixIconModule,
  RiArrowLeftSLine,
  RiArrowRightSLine,
  RiAttachmentLine,
  RiChat3Line,
  RiCloseLine,
  RiHome2Line,
  RiMovieLine,
  RiSendPlane2Line,
  RiSubtractLine,
  RiThumbDownFill,
  RiThumbDownLine,
  RiThumbUpFill,
  RiThumbUpLine,
  RiWindow2Line,
} from 'angular-remix-icon';
import { FileUploadModule } from 'ng2-file-upload';
import { AutosizeModule } from 'ngx-autosize';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { ButtonComponent } from './button/button.component';
import { DropZoneDirective } from './drop-zone.directive';
import { MessageComponent } from './message/message.component';
import { ModalModule } from './modal';
import { SafeUrlPipe } from './pipes/safe-url.pipe';
import { SanitizeMessagePipe } from './pipes/sanitize-message.pipe';
import { PopTriggerMessageComponent } from './pop-triggers/pop-trigger-message/pop-trigger-message.component';
import { PopTriggersComponent } from './pop-triggers/pop-triggers.component';
import { SatisfactionComponent } from './satisfaction/satisfaction.component';
import { SatisfactionModule } from './satisfaction/satisfaction.module';
import { AddHeaderInterceptor } from './services/add-header-interceptor';
import { DisplayService } from './services/display.service';
import { RedirectService } from './services/redirect.service';
import { WebviewComponent } from './webview/webview.component';
import { WidgetComponent } from './widget/widget.component';
import { CarouselComponent } from './carousel/carousel.component';

export function httpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, 'assets/i18n/');
}

@NgModule({
  declarations: [
    SanitizeMessagePipe,
    SafeUrlPipe,
    AppComponent,
    WidgetComponent,
    ButtonComponent,
    MessageComponent,
    PopTriggersComponent,
    SatisfactionComponent,
    WebviewComponent,
    DropZoneDirective,
    PopTriggerMessageComponent,
    CarouselComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    BrowserAnimationsModule,
    NgScrollbarModule,
    ModalModule,
    AutosizeModule,
    HttpClientModule,
    ClickOutsideModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpLoaderFactory,
        deps: [HttpClient],
      },
      defaultLanguage: 'en',
    }),
    FileUploadModule,
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideFirestore(() => getFirestore()),
    FlexLayoutModule,
    NgxSkeletonLoaderModule,
    SatisfactionModule,
    RemixIconModule.configure({
      RiChat3Line,
      RiWindow2Line,
      RiArrowLeftSLine,
      RiArrowRightSLine,
      RiThumbDownLine,
      RiThumbDownFill,
      RiThumbUpLine,
      RiThumbUpFill,
      RiCloseLine,
      RiHome2Line,
      RiSubtractLine,
      RiAttachmentLine,
      RiSendPlane2Line,
      RiMovieLine,
    }),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AddHeaderInterceptor,
      multi: true,
    },
    DisplayService,
    RedirectService,
    DatePipe,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(private injector: Injector) {}
}
