export function trimUrl(urlInput: URL | string) {
  const url = urlInput instanceof URL ? urlInput : new URL(urlInput);
  const result = `${url.protocol}//${url.host}${url.pathname}`;
  if (result.substr(-1, 1) === '/') {
    return result.substr(0, result.length - 1);
  }
  return result;
}

export function openUrl(url: string) {
  const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
  newWindow?.focus();

  if (newWindow) {
    newWindow.opener = null;
  }
}

export function jsonCopy<T>(e: T) {
  try {
    return JSON.parse(JSON.stringify(e)) as T;
  } catch (err) {
    return undefined;
  }
}

/**
 * Deeply compare two objects using Json.stringify
 * and returns if their json string are strictly equal.
 *
 * Will return false if a deep comparison is made on an
 * object leading to a circular reference.
 * Only compare simple literal like objects.
 */
export function jsonMatch(first: any, second: any): boolean {
  try {
    return JSON.stringify(first) === JSON.stringify(second);
  } catch (err) {
    return false;
  }
}

/**
 * Polyfill for the URL.canParse method (Node.js > 20).
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/API/URL/canParse_static
 */
export function canParse(url: string, base?: string): boolean {
  try {
    // eslint-disable-next-line no-new
    new URL(url, base);
  } catch (e) {
    return false;
  }
  return true;
}
