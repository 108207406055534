<div
  fxLayout="row"
  fxLayoutAlign="start center"
  class="top"
  [style.background]="displayService.chatStyle.headerStyle.background"
  [ngStyle]="displayService.chatStyle.headerStyle.textAndLogo"
>
  <rmx-icon
    name="arrow-left-s-line"
    class="s-xl"
    [ngStyle]="displayService.chatStyle.headerStyle.textAndLogo"
    (click)="hideWebview()"
  ></rmx-icon>
</div>
<iframe
  id="internalIframe"
  title="webviewInsideWidget"
  width="100%"
  height="100%"
  frameBorder="0"
  [src]="urlToBeSanitized | safeUrl"
>
</iframe>
