import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-satisfaction-rating',
  templateUrl: './satisfaction-rating.component.html',
  styleUrls: ['./satisfaction-rating.component.scss'],
})
export class SatisfactionRatingComponent {
  @Output() satisfactionNote = new EventEmitter<number>();
  @Output() skipSatisfaction = new EventEmitter<void>();

  public satisfactionNotes: number[] = [1, 2, 3, 4, 5];
  public satisfactionComment: string = '';

  constructor() {}

  public calculateNote(note: number): void {
    const maxPoints = 100;
    const pointsPerNote = maxPoints / this.satisfactionNotes.length;

    this.satisfactionNote.emit(pointsPerNote * note);
  }
}
