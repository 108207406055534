import { Injectable, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root',
})
export class SentMessageSanitizerService {
  constructor(private sanitizer: DomSanitizer) {}

  private decodeHtmlEntities(encodedString: string) {
    const textArea = document.createElement('textarea');
    textArea.innerHTML = encodedString;
    return textArea.value;
  }

  private encodeHtmlTags(html: string): string {
    return html.replace(/</g, '&lt;').replace(/>/g, '&gt;');
  }

  sanitize(html: string) {
    // Angular transform all special chars, including UTF-8 ones to their htmlentities equivalent.
    const rawSanitization = this.sanitizer.sanitize(SecurityContext.HTML, html);

    // Decode back htmlentities to UTF-8
    const rawUtf8 = this.decodeHtmlEntities(rawSanitization);

    // Keep the whole sanitized string as is but keep HTML tags as HTML entities.
    const sanitizedUtf8 = this.encodeHtmlTags(rawUtf8);

    return sanitizedUtf8;
  }
}
