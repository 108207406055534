import { Component, Input, Output, EventEmitter } from '@angular/core';
import { SafeResourceUrl } from '@angular/platform-browser';
import { DisplayService } from '../services/display.service';

@Component({
  selector: 'app-webview',
  templateUrl: './webview.component.html',
  styleUrls: ['./webview.component.scss'],
})
export class WebviewComponent {
  @Output() hide = new EventEmitter<void>();
  @Input() urlToBeSanitized: string;

  public urlToRender: SafeResourceUrl;

  constructor(public displayService: DisplayService) {}

  public hideWebview(): void {
    this.hide.emit();
  }
}
