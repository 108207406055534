import { ZDepartment } from '../services/redirect-zendesk.service';
import { User } from './user';
import { Message, ThreadStatus } from './message';
import { WidgetChatStyle } from './widgetChatStyle';

export enum TriggerStatus {
  NONE = 'none',
  WAITING = 'waiting',
  ABORTED = 'aborted',
  CANCELED = 'canceled',
  OVERRIDDEN = 'overridden',
  ENGAGING = 'engaging',
  ENGAGED = 'engaged',
  WELCOMING = 'welcoming',
  WELCOMING_ENGAGING = 'welcoming_engaging',
}

export interface TriggerData {
  delay: number;
  messages: Message[];
  popUp: boolean;
  overridingActiveThread: boolean;
  isPopTriggerOnly: boolean;
  triggerId: string;
  identifier: string;
}

export interface WelcomeTriggerData extends TriggerData {
  welcomeMessageId: string;
  hideTextInput: boolean;
}

export enum RHType {
  SLACK = 'slack',
  ZENDESK = 'zendesk',
  FRONTAPP = 'frontapp',
  EASICHAT = 'easichat',
  ZENDESKMESSAGING = 'zendesk-messaging',
}

export interface RHInit {
  type: RHType;
  data: any;
  actor: User;
}

export interface HandlerData {
  handler_type: RHType;
}

export interface SlackHandlerData extends HandlerData {
  handler_type: RHType.SLACK;
}
export interface ZendeskHandlerData extends HandlerData {
  handler_type: RHType.ZENDESK;
  departments: ZDepartment[];
  selectedDepartmentId: number | string;
}
export interface FrontappHandlerData extends HandlerData {
  handler_type: RHType.FRONTAPP;
  availableTeamMates: number;
}

export interface EasichatHandlerData extends HandlerData {
  handler_type: RHType.EASICHAT;
}

export enum EngageType {
  NORMAL = 1,
  WELCOME = 2,
  LIVE = 3,
  CATCH = 4,
}

export interface TranslationElem<T> {
  language: string | null;
  content: T;
}

export type TranslationArray<T> = TranslationElem<T>[];

export interface Session {
  id: string;
  version: string;
  accountId: string;
  chatStyle: WidgetChatStyle;
  enabled: boolean;
  mobileEnabled: boolean;
  open: boolean;
  customer: User | null;
  threadId: string;
  threadStatus: ThreadStatus | null;
  triggerStatus: TriggerStatus;
  rhInit: RHInit | null;
  rhData: HandlerData | null;
  redirectionEnabled: boolean;
  selfCareEnabled: boolean;
  selfCareTitle: string;
  selfCareActionButtonsEnabled: boolean;
  selfCareActionButtonsDisplayed: boolean;
  selfCareAskButtonEnabled: boolean;
  selfCareAskButtonText: TranslationArray<string>;
  expectingMoreMessages: boolean;
  availableAgents: number;
  availableThirdPartyAgents: number;
  dismissedPopUp: boolean;
  pathname: string;
  language: string;
  triggerData: TriggerData | null;
  welcomeTriggerData: WelcomeTriggerData | null;
  popOnlyMessages: Message[];
  urls: { pathname: string; href: string }[];
  created: number;
  resetted: number;
  satisfaction: number | null;
  comment: string | null;
  promptSatisfaction: boolean;
  dismissedSatisfaction: number;
  isMobile: boolean;
  userAgent: string;
  visitorIsTyping: boolean;
  agentIsTyping: User | false;
  thirdPartyTakeover: null | 'easychat' | 'zendesk-external';
  widgetTitle: string | null;
  noUserMessageSent: boolean;
}

export enum ChatEventType {
  SESSION_CREATED = 0,
  OPEN = 1,
  CLOSE = 2,
  IGNORE_SATISFACTION = 3,
  ASKED_SATISFACTION = 4,
  CUSTOM_EVENT = 5,
  HANDSHAKE = 6,
  ORDER_VALIDATED = 7,
  GDPR_ACCEPT_ON_INIT = 8,
  GDPR_ACCEPT_THROUGH_API = 9,
  GDPR_ACCEPT_CLICK = 10,
  GDPR_DECLINE_CLICK = 11,
}
