import { Component, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { WidgetComponent } from './widget/widget.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'widget';

  @ViewChild('widget', { static: true }) widgetComponent: WidgetComponent;

  constructor(translate: TranslateService) {
    translate.setDefaultLang('en');
  }
}
