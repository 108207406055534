import { TranslationArray } from './Session';

export enum ActorType {
  LEAD = 'lead',
  USER = 'visitor',
  AGENT = 'agent',
  SYSTEM = 'system',
  BOT = 'bot',
  THIRD_PARTY = 'third_party',
}

export class User {
  public translatedNames?: TranslationArray<{ firstName: string; lastName: string }>;

  public constructor(
    public email: string,
    public firstName: string,
    public lastName: string,
    public type: ActorType,
    public id: string | null,
    public avatar: string,
    public customData: { key: string; value: string }[],
  ) {}
  public static getFullName(user: User): string {
    if (user.firstName && user.lastName) {
      return `${user.firstName} ${user.lastName}`;
    }
    if (user.firstName) {
      return user.firstName;
    }
    if (user.lastName) {
      return user.lastName;
    }
  }
}
