<div
  #popMessageWrapper
  class="pop-message-wrapper"
  *ngIf="config.message"
  [class]="'pop-message-wrapper ' + config.message.type + '-message'"
  [ngClass]="{ animate: animate, measuring: !measured }"
  [ngStyle]="{ 'transition': 'height ' + animationLength + 's ease-in-out ' + animationDelay + 's, opacity ' + animationLength + 's ease-in-out ' + animationDelay +'s;' }"
  [style.height]="destinationHeight + 'px'"
>
  <div #popMessage class="pop-message" [ngSwitch]="config.message.type">
    <div #popMessageContent class="pop-message-content">
      <ng-container *ngSwitchCase="'text'">{{ config.message.content }}</ng-container>

      <ng-container *ngSwitchCase="'text_payload'">{{ config.message.content.text }}</ng-container>

      <ng-container *ngSwitchCase="'image'">
        <img [src]="config.message.content | safeUrl" (load)="onLoad()" />
      </ng-container>

      <ng-container *ngSwitchCase="'video'">
        <video autoplay [muted]="'muted'" controls width="100%">
          <source [src]="config.message.content | safeUrl" type="video/mp4" (loadeddata)="onLoad()" />
          Sorry, your browser doesn't support embedded videos...
        </video>
      </ng-container>

      <ng-container *ngSwitchCase="'buttons'">
        <div
        [ngStyle]="{ justifyContent: displayService.widgetOnRight ? 'flex-end' : 'flex-start' }"
        >
          <div
            *ngFor="let item of config.message.content; let buttonIndex = index"
            (mouseover)="itemHoverInd = buttonIndex"
            (mouseleave)="itemHoverInd = -1"
            [ngStyle]="getButtonsItemStyle(buttonIndex)"
          >
            <a
              *ngIf="item.type === 'link'"
              [href]="item.content"
              target="_blank"
              style="text-decoration: none; color: inherit"
              [ngStyle]="getButtonsItemContentStyle(buttonIndex)"
            >
              {{ item.text }}
            </a>
            <span *ngIf="item.type === 'reply'" (click)="clickButton(config.message, item)">
              {{ item.text }}
            </span>
          </div>
        </div>
      </ng-container>

      <ng-container *ngSwitchCase="'quick_replies'">
        <div *ngFor="let item of config.message.content">
          {{ item }}
        </div>
      </ng-container>

      <ng-container *ngSwitchCase="'attachment'">
        <a [href]="config.message.content.url" target="_blank">
          <img [src]="attachmentSrc(config.message)" width="80" height="80" />
        </a>
      </ng-container>
    </div>
  </div>
</div>
