import { Injectable } from '@angular/core';
import { interval, Observable, Subscription } from 'rxjs';
import { BroadcastChannel } from 'broadcast-channel';

interface BcTabPing {
  id: string;
  createdAt: number;
  liveAt: number;
  dead: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class MultiTabService {
  private id: string;
  private createdAt: number;
  private tabs: BcTabPing[] = [];
  private channel: BroadcastChannel<BcTabPing>;
  private source: Observable<number>;
  private subscription: Subscription;

  public constructor() {}

  public init(sessionId: string) {
    if (this.channel) {
      this.channel.close();
    }
    if (this.source && this.subscription) {
      this.subscription.unsubscribe();
      delete this.subscription;
      delete this.source;
    }
    this.id = `${performance.now()}:${this.someNumber()}`;
    this.createdAt = Date.now();
    this.tabs = [{ id: this.id, createdAt: this.createdAt, liveAt: Date.now(), dead: false }];
    this.channel = new BroadcastChannel<BcTabPing>(`bc_multiTab-${sessionId}`, {
      webWorkerSupport: false,
    });

    this.channel.onmessage = (ping) => {
      if (ping.id === this.id) {
        return;
      }
      const el = this.tabs.find((e) => e.id === ping.id);
      if (el) {
        el.liveAt = ping.liveAt;
      } else {
        this.tabs.push(ping);
      }
    };
    this.source = interval(200);
    this.subscription = this.source.subscribe((val) => {
      // Signal other tabs that this tab is alive
      this.channel.postMessage({
        id: this.id,
        createdAt: this.createdAt,
        liveAt: Date.now(),
        dead: false,
      });
      // Mark unresponsive tabs as dead
      const now = Date.now();
      for (const tab of this.tabs) {
        tab.dead = tab.id !== this.id && now - tab.liveAt > 2000;
      }
    });
  }

  private getLatestCreatedTab(): BcTabPing | null {
    const liveTabs = this.tabs.filter((t) => !t.dead).sort((a, b) => b.createdAt - a.createdAt);
    if (liveTabs.length > 0) {
      return liveTabs[0];
    }
    return null;
  }

  public getIsActiveTab(): boolean {
    const latestCreatedTab = this.getLatestCreatedTab();
    return latestCreatedTab ? latestCreatedTab.id === this.id : false;
  }

  private someNumber(n = 1000000000) {
    return (Math.random() * n) | 0;
  }
}
