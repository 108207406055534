<div
  class="main"
  fxLayout="column"
  fxLayoutAlign="end "
  fxFill
  [ngStyle]="{
    'border-radius': widget.displayService.freeDivMode ? '0px' : '15px',
    gap: widget.displayService.chatStatus === 'closed' ? '7.5px' : null
  }"
>
  <app-widget
    fxFlex="nogrow"
    #widget
    [fxHide]="widget.displayService.chatStatus !== 'open'"
  ></app-widget>

  <app-pop-triggers
    fxFlex="nogrow"
    [fxHide]="widget.displayService.chatStatus === 'open'"
    [widget]="widget"
  ></app-pop-triggers>

  <app-button
    fxFlex="nogrow"
    [fxLayoutAlign]="widget.displayService.widgetOnRight ? 'end center' : 'start center'"
    [fxHide]="widget.displayService.chatStatus === 'open'"
    [widget]="widget"
  ></app-button>
</div>
