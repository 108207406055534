import { trigger, transition, animate, keyframes, style } from '@angular/animations';
import { Component, Input, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { DisplayService } from '../services/display.service';
import { WidgetComponent } from '../widget/widget.component';

const animationDurationSeconds: number = 0.5;

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  animations: [
    trigger('scaleUpWidgetButton', [
      transition(':enter', [
        animate(
          `${animationDurationSeconds}s ease-in-out`,
          keyframes([
            style({
              opacity: 0,
              transform: `scale(0)`,

              offset: 0,
            }),
            style({
              opacity: 0.75,
              transform: `scale(0.25)`,

              offset: 0.35,
            }),
            style({
              opacity: 1,
              transform: `scale(1.05)`,

              offset: 0.65,
            }),
            style({
              opacity: 1,
              transform: `scale(1)`,

              offset: 1,
            }),
          ]),
        ),
      ]),
    ]),
  ],
})
export class ButtonComponent implements OnInit, AfterViewInit {
  @Input() widget: WidgetComponent;
  @ViewChild('animationVideo', { static: false }) animationVideo: ElementRef;

  public animationLoaded: boolean = false;
  public showAnimation: boolean = false;

  constructor(public displayService: DisplayService) {}
  ngAfterViewInit(): void {
    this.addAccessibilityOnSvg();
  }

  ngOnInit() {
    this.displayService.launcherAnimationSubject.subscribe(({ url }) => {
      if (this.animationVideo?.nativeElement && url) {
        this.startAnimation(this.displayService.chatStyle.launcherAnimationDelay);
      }
    });
  }

  public toggleChatStatus(s: boolean) {
    this.stopAnimation();
    this.displayService.toggleChatStatus(s, this.widget);
  }

  public startAnimation(delay: number = this.displayService.chatStyle.launcherAnimationDelay): void {
    if (delay) {
      setTimeout(() => {
        this.showAnimation = true;

        this.animationVideo.nativeElement.setAttribute('autoplay', true);
        this.animationVideo.nativeElement.play();
      }, delay * 1000);
    }
  }

  public stopAnimation(): void {
    if (
      this.displayService.chatStyle.launcherAnimationUrl &&
      this.displayService.chatStyle.launcherAnimationUrl !== '' &&
      this?.animationVideo?.nativeElement
    ) {
      this.animationVideo.nativeElement.setAttribute('autoplay', false);
      this.animationVideo.nativeElement.pause();

      this.animationVideo.nativeElement.currentTime = 0;
    }

    this.showAnimation = false;
  }
  public addAccessibilityOnSvg() {
    const icon = document?.getElementsByClassName('rmx-icon-chat-3-line')?.[0];
    const containedSvg = icon?.children?.[0];
    containedSvg?.setAttribute('aria-hidden', 'true');
    containedSvg?.setAttribute('focusable', 'false');
  }
}
