<section fxLayout="column">
  <ng-container [ngSwitch]="currentStep">
    <app-satisfaction-rating
      fxFlex="nogrow"
      *ngSwitchCase="'rating'"
      (satisfactionNote)="handleSatisfactionNote($event)"
      (skipSatisfaction)="handleSkipSatisfaction()"
    ></app-satisfaction-rating>

    <app-satisfaction-feedback
      fxFlex="nogrow"
      *ngSwitchCase="'feedback'"
      (validateFeedback)="handleValidateFeedback($event)"
    ></app-satisfaction-feedback>

    <ng-container *ngSwitchCase="'end'">
      <p fxFlex="nogrow" *ngIf="note === 100">{{ 'SATISFACTION_END_POSITIVE' | translate }}</p>
      <p fxFlex="nogrow" *ngIf="note === 50">{{ 'SATISFACTION_END_AVG' | translate }}</p>
      <p fxFlex="nogrow" *ngIf="note === 0">{{ 'SATISFACTION_END_NEGATIVE' | translate }}</p>
      <p fxFlex="nogrow" *ngIf="note">{{ 'SATISFACTION_END_COMMENT' | translate }}</p>

      <button
        fxFlex="nogrow"
        type="button"
        class="end-satisfaction-button"
        (click)="handleCloseSatisfaction()"
      >
        {{ 'CLOSE_SATISFACTION_PROMPT' | translate }}
      </button>
    </ng-container>
  </ng-container>
</section>
