export type MediaType = 'image' | 'video';

export const extensionToMediaTypeMap: { [key: string]: MediaType } = {
  jpg: 'image',
  jpeg: 'image',
  png: 'image',
  gif: 'image',
  bmp: 'image',
  webp: 'image',
  avif: 'image',
  mp4: 'video',
  avi: 'video',
  mov: 'video',
  mkv: 'video',
  webm: 'video',
};

const carouselItemClickablePropertiesValues = ['mediaUrl', 'description'] as const;
export type CarouselItemClickableProperties = (typeof carouselItemClickablePropertiesValues)[number];

export type CarouselItem = {
  mediaUrl: string;
  mediaType?: MediaType;
  url: string;
  description: string;
  clickable: CarouselItemClickableProperties[];
};
