export class Mutex {
  private isLocked: boolean;
  private pendingLockRequests: { promise: Promise<void>; resolver: Function }[];
  public constructor() {
    this.isLocked = false;
    this.pendingLockRequests = [];
  }

  public async lock() {
    if (!this.isLocked) {
      this.isLocked = true;
      return;
    }
    let lockResolve: Function;
    const lockRequest = new Promise<void>((resolve, reject) => {
      lockResolve = resolve;
    });
    this.pendingLockRequests.push({ promise: lockRequest, resolver: lockResolve });
    return lockRequest;
  }

  public unlock() {
    this.isLocked = false;
    if (this.pendingLockRequests.length) {
      const [earliestLock] = this.pendingLockRequests.splice(0, 1);
      earliestLock.resolver();
      this.isLocked = true;
    }
  }
}
