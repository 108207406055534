<div
  class="main"
  fxLayout="column"
  [ngStyle]="displayService.getPopNotifStyle()"
  [ngClass]="{
    mobile: displayService.isMobileDisplay,
    widgetOnRight: displayService.widgetOnRight
  }"
  #mainContainer
>
  <ng-container *ngIf="popTriggerService.messages?.length > 0">
    <div
      fxLayout="column"
      fxFlex="nogrow"
      [fxLayoutAlign]="displayService.widgetOnRight ? 'end end' : 'start start'"
      #popMessagesContainer
    >
      <div fxLayout="row" fxFlex="nogrow" fxLayoutAlign="end center" #dismissContainer>
        <div
          fxFlex="nogrow"
          fxLayoutAlign="center center"
          class="dismiss-pop-trigger"
          (click)="dismiss()"
          [ngClass]="{ visible: messagesLoaded }"
        >
          <rmx-icon fxFlex="nogrow" name="close-line" class="s-xl"></rmx-icon>
        </div>
      </div>

      <ng-container
        *ngFor="let message of messages; let messageIndex = index; trackBy: messageTrackByFn"
      >
        <!-- Only the first text message is shown on mobile display -->
        <div
          [ngStyle]="{
            height:
              displayService.isMobileDisplay && message?.uuid !== firstTextMessage?.uuid
                ? 0
                : 'auto'
          }"
          #popMessages
          class="pop-message-item"
          (click)="click(message)"
        >
          <app-pop-trigger-message
            [config]="{
                message: popTriggerService.messages?.[messageIndex],
                messageIndex,
                canAnimate: messagesLoaded
              }"
            (reply)="clickButton($event)"
            (loadingEnd)="incrementLoadedMessagesNumber()"
            (animationStart)="animationStart($event)"
          ></app-pop-trigger-message>
        </div>
      </ng-container>
    </div>
  </ng-container>
</div>
